@import url(./assets/css/common.css);
@import url(./assets/css/common.dartheme.css);
@import url(./assets/css/overrides.css);

.ant-table.ant-table-small
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
}
.g2-html-annotation {
  font-size: 22px ;
}

.nowrap {
  white-space: nowrap;
}

#analytics .ant-picker-calendar-date-content {
  height: 125px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
